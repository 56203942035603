import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import PageTemplate2 from "./page2"

const filterByYear = (items, year) => {
  return items.filter(item => {
    const myDate = new Date(item.date)
    return myDate.getFullYear() === year
  })
}

const TabPanel = ({ value, index, children }) => {
  return <Box hidden={value !== index} width="100%">{children}</Box>
}

const ItemCard = ({url, thumbnail, title, collection}) => {
  const [showDialog, setShowDialog] = useState(false)
  return (
    <>
      <Card>
        <CardActionArea onClick={() => setShowDialog(true)} /* href={url} */>
          {thumbnail && (
            <CardMedia style={{ position: "relative" }}>
              <GatsbyImage
                image={thumbnail.childImageSharp.gatsbyImageData}
                alt={title}
                style={{ height: "300px" }}
                objectPosition={"top"}
              />
              <CardHeader
                disableTypography
                style={{
                  position: "absolute",
                  bottom: 0,
                  color: "#FFF",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  paddingTop: 8,
                  paddingBottom: 8,
                  fontFamily: "Zilla Slab",
                  fontWeight: 700,
                  fontSize: "1.25rem",
                }}
                title={title}
              />
            </CardMedia>
          )}
        </CardActionArea>
      </Card>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          <Typography variant="h5">{collection} ({title})</Typography>
          <IconButton
            onClick={() => setShowDialog(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GatsbyImage
            image={thumbnail.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

const CollectionTemplate = ({ pageContext: { frontmatter, html } }) => {
  const [showTab, setShowTab] = useState(0)

  return (
    <PageTemplate2 title={frontmatter.title}>
      {frontmatter.yt && (
        <Box position="relative" mt={4} pb={`${(3 / 4) * 100}%`} height={0}>
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`https://www.youtube.com/embed/${frontmatter.yt}?rel=0&modestbranding=1`}
            title="YouTube video player"
            frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      )}
      {frontmatter.soundcloud && (
        <iframe
          title="SoundCloud player"
          width="100%"
          height="166"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${frontmatter.soundcloud}&color=%23d03200&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
        ></iframe>
      )}

      <div dangerouslySetInnerHTML={{ __html: html }} />

      {frontmatter.displayYears && (
        <Box display="flex">
          <Tabs
            orientation="vertical"
            value={showTab}
            onChange={(event, newValue) => setShowTab(newValue)}
            style={{ minWidth: 40, paddingTop: 80 }}
            indicatorColor="primary"
            textColor="primary"
          >
            {frontmatter.displayYears?.map(year => (
              <Tab label={year} key={year} style={{ minWidth: 40 }} />
            ))}
          </Tabs>
          {frontmatter.displayYears?.map((year, index) => {
            const filteredItems = filterByYear(frontmatter.items, year).sort(
              (a, b) => {
                return new Date(a.date) - new Date(b.date)
              }
            )
            return (
              <TabPanel value={showTab} index={index} key={year}>
                <Box pl={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="h2">{year}</Typography>
                    <Typography>{`${filteredItems.length} issue${filteredItems.length > 1 ? "s" : ""}`}</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {filteredItems?.map(({ title, url, thumbnail }, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <ItemCard url={url} thumbnail={thumbnail} title={title} collection={frontmatter.title} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
            )})}
        </Box>
      )}
    </PageTemplate2>
  )
}

export default CollectionTemplate
